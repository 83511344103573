import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import '../styles/_valentines.scss';

import valentinesGif from '../images/valentines.png';
import { useLocation } from 'react-router-dom';

const Valentines = () => {
    const [viewTitle, setViewTitle] = useState(true);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/valentinesday/larissa') {
            document.body.classList.add('valentine-page');
        } else {
            document.body.classList.remove('valentine-page');
        }
    }, [location.pathname]);

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 400px)");
    const tabletMediaQuery = window.matchMedia(
      "(min-width: 400px) and (max-width: 600px)"
    );
    const notes = document.querySelectorAll(".js-note");

    const resizeNotes = () => {
      notes.forEach(note => {
        if (note.classList.contains("active")) {
          note.classList.remove("active");
          gsap.set(note, {
            height: "30%",
            clearProps: "all"
          });
        }
      });
    };

    const notesReady = () => {
      gsap.to(".js-envelop-content", {
        height: "110%",
        duration: 0.5
      });

      notes.forEach((note, index) => {
        note.addEventListener("click", () => {
          if (mobileMediaQuery.matches) {
            handleNoteClick(note, 125, 40, index);
          } else if (tabletMediaQuery.matches) {
            handleNoteClick(note, 80, 21, index);
          } else {
            handleNoteClick(note, 70, 20, index);
          }
        });
      });
    };

    const handleNoteClick = (note, baseHeight, increment, index) => {
      if (note.classList.contains("active")) {
        note.classList.remove("active");
        gsap.set(note, {
          height: "30%",
          clearProps: "all"
        });
      } else {
        notes.forEach(n => {
          if (n.classList.contains("active")) {
            n.classList.remove("active");
            gsap.set(n, {
              height: "30%",
              clearProps: "all"
            });
          }
        });
        note.classList.add("active");
        gsap.set(note, {
          height: `${baseHeight + increment * index}%`
        });
      }
    };

    const setUpPaper = () => {
      const arr = [0, 0, 100, 0, 50, 61];
      gsap.set(".js-up-paper", {
        bottom: "97%",
        rotation: 180,
        zIndex: 200,
        clipPath:
          `polygon(${arr[0]}% ${arr[1]}%, ${arr[2]}% ${arr[3]}%, ${arr[4]}% ${arr[5]}%)`,
        onComplete: notesReady
      });

      setViewTitle(false);
    };

    const envelopTransition = () => {
      gsap.to(".js-up-paper", {
        bottom: "1%",
        duration: 0.25,
        onComplete: setUpPaper
      });
      document.querySelector(".js-up-paper").removeEventListener("click", envelopTransition);
      document.querySelector(".js-up-paper").classList.remove("cursor");
    };

    const sticker = () => {
      gsap.set(".js-sticker", { width: "20%", left: "-80%" });
      document.body.classList.remove("scissors");
      document.querySelector(".js-sticker").removeEventListener("click", sticker);
      document.querySelector(".js-up-paper").addEventListener("click", envelopTransition);
      document.querySelector(".js-up-paper").classList.add("cursor");
    };

    document.querySelector(".js-sticker").addEventListener("click", sticker);

    window.onresize = resizeNotes;

    document.title = "Feliz Dia dos Namorados!";

    return () => {
      notes.forEach(note => note.removeEventListener("click", handleNoteClick));
      document.querySelector(".js-sticker").removeEventListener("click", sticker);
      window.onresize = null;
    };
  }, []);

  return (
      <main className="scissors">
            <div className={`title ${!viewTitle ? 'open' : ''}`}>
                <img src={valentinesGif} alt="Valentines" />
                <h1>Feliz <b>Dia dos Namorados</b> !</h1>
                {viewTitle && <p className='legend'>💡 Clique em cima da faixa para cortar e abrir a carta</p>}
            </div>
      <div className="envelop">
        <div className="envelop__front-paper"></div>
        <div className="envelop__back-paper"></div>
        <div className="envelop__up-paper js-up-paper"></div>
        <div className="envelop__sticker js-sticker"></div>
        <div className="envelop__false-sticker"></div>
        <div className="envelop__content js-envelop-content">
          <div className="love-notes">
            <div className="note js-note">
              <div className="note__text">
                <p>Oi, <b className='pink-text'>Larissa </b>❤️. Quero te agradecer por todos os momentos incríveis que já passamos...</p>
              </div>
            </div>
            <div className="note js-note">
              <div className="note__text">
                 <p>Desejo que esses momentos nunca terminem e que tenhamos muitos outros ainda pela frente.</p>
                <p>Acredito que Deus sempre coloca as pessoas certas nas horas certas, e <b>você é a prova disso.</b></p>
              </div>
            </div>
            <div className="note js-note">
              <div className="note__text">
                <p>Seu amor me inspira a acreditar que é possível casar e construir uma família, e cada momento ao seu lado é incrível!</p> <p><b>Te amo mais do que palavras podem expressar! </b>❤️</p>
                <span>Com amor, <b>Bruno Sapalacio</b>.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Valentines;
