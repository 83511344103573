import Valentines from "./pages/valentines.js";
import MainHome from "./pages/home";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainHome />}/>
        <Route path="/valentinesday/larissa" element={<Valentines />}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
